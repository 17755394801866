import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby';
import { Image } from './Images'
import { connect } from 'react-redux'
import { setImg, setAlt, setDescription, setCaption, setProduct } from '../state/app'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function ChangeImage(dispatch, url, alt, description, caption) {
    if (typeof window !== "undefined") {
        dispatch(setImg(url))
        dispatch(setAlt(alt))
        dispatch(setDescription(description))
        dispatch(setCaption(caption))
        //document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
    }
}

const CarouselComponent = ({ carousel, kitchen, carpentry, dispatch, global, products, useImg, useAlt, useDescription, useCaption }) => {

    const handleSelectGlobalCarousel = (dispatch, url, alt, description, caption) => {
        if (typeof window !== "undefined") {
            dispatch(setImg(url))
            dispatch(setAlt(alt))
            dispatch(setDescription(description))
            dispatch(setCaption(caption))
            
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };


    const handleSelect = (dispatch, url, link) => {
        var productFind = products.filter(product => product.image === url)
        dispatch(setImg(url))
        dispatch(setAlt(productFind[0].alt))
        dispatch(setDescription(productFind[0].info.description))
        dispatch(setCaption(productFind[0].caption))
        navigate(link)
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
        }
    }
    if (global && !kitchen && !carpentry) {

        return (
            <CarouselStyled>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={5000}
                    autoPlay={true}
                    keyBoardControl={true}
                    customTransition="all 1"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    centerMode={true}
                >
                    {carousel.map((content, j) =>
                        <div
                            className='content'
                            key={j}
                        >
                            <Image src={content.image} alt={content.alt} />
                        </div>
                    )
                    }
                </Carousel>
            </CarouselStyled>
        )
    }
    if (global && (kitchen || carpentry)) {
        return (
            <CarouselStyled>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={5000}
                    autoPlay={true}
                    keyBoardControl={true}
                    customTransition="all 1"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    centerMode={true}
                >
                    {carousel.map((content, i) =>
                        <div
                            className='content pointer'
                            key={i}
                            onClick={() => (handleSelect(dispatch, content.image, content.link))}
                            onKeyDown={() => (handleSelect(dispatch, content.image, content.link))}
                            role='button'
                            tabIndex={0}
                        >
                            <Image src={content.image} alt={content.alt} />
                        </div>

                    )
                    }
                </Carousel>
            </CarouselStyled>
        )
    }

    return (
        <>
            <CarouselStyled>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={5000}
                    autoPlay={true}
                    keyBoardControl={true}
                    customTransition="all 1"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    centerMode={true}
                >
                    {carpentry &&
                        carousel.map((content, i) =>
                            <div
                                className='content pointer'
                                key={i}
                                onClick={() => (handleSelectGlobalCarousel(dispatch, content.image, content.alt, content.description, content.caption, content.link))}
                                onKeyDown={() => (handleSelectGlobalCarousel(dispatch, content.image, content.alt, content.description, content.caption, content.link))}
                                role='button'
                                tabIndex={0}
                            >
                                <Image src={content.image} alt={content.alt} />
                            </div>
                        )
                    }
                    {kitchen &&
                        carousel.map((content, i) => (
                            <div
                                className='content pointer'
                                key={i}
                                onClick={() => ChangeImage(dispatch, content.image, content.alt, content.description, content.caption, setCaption, setImg, setAlt, setDescription)}
                                onKeyDown={() => ChangeImage(dispatch, content.image, content.alt, content.description, content.caption, setCaption, setImg, setAlt, setDescription)}
                                role='button'
                                tabIndex={0}
                            >
                                <Image src={content.image} alt={content.alt} />
                            </div>
                        ))
                    }
                    {(!kitchen && !carpentry) &&
                        carousel.map((content, i) => (

                            <div
                                className='content'
                                key={i}
                                onClick={() => dispatch(setProduct({ open: true, id: content.id }))}
                                onKeyDown={() => dispatch(setProduct({ open: true, id: content.id }))}
                                role='button'
                                tabIndex={0}
                            >
                                <Image src={content.image} alt={content.alt} />
                            </div>
                        ))}
                </Carousel>
            </CarouselStyled>
        </>
    )
}

export default connect(state => ({
    useImg: state.app.img,
    useAlt: state.app.alt,
    useDescription: state.app.description,
    useCaption: state.app.caption,
    product: state.app.product
}), null)(CarouselComponent)


const CarouselStyled = styled.div`
.react-multi-carousel-track {
    transition: all 2000ms ease !important;
}
.pointer{
    cursor: pointer;
}
.react-multi-carousel-dot button {
    display: inline-block;
    width: 30px;
    height: 10px;
    border-radius: 0px; 
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background .5s;
    border-width: 2px;
    border-style: solid;
    border-color: #D3D3D3;
    padding: 0;
    margin: 0;
    margin-right: 0px;
    outline: 0;
    cursor: pointer;
    background-color: #D3D3D3;
}
.custom-dot-list-style li:first-child > button{
    border-top-left-radius: 3px !important; 
    border-bottom-left-radius: 3px !important; 
}
.custom-dot-list-style li:last-child > button{
    border-top-right-radius: 3px !important; 
    border-bottom-right-radius: 3px !important; 
}
.react-multi-carousel-dot--active button {
    background-color: #1b1c22 !important;
}
.react-multi-carousel-item {
    padding-right: 30px;
    .content{
        height: 60vh;
        min-height: 60vh;
        max-height: 60vh;
        .image{
            height: 100%;
        }
    }
}

@media (max-width: 990px){
    .react-multi-carousel-item {
        .content{
            height: 30vh;
            min-height: 30vh;
            max-height: 30vh;
        }
    }
}
`